.full-width-block {
  color: $color--purple;
  font-size: $text--4;
  background-color: $color--beige;

  &__content {
    position: relative;
    overflow: hidden;
  }

  &__image {
    position: absolute;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: auto;
    min-width: 50%;
    min-height: 100%;

    // display on larger viewports
    display: none;
  }

  &__description {
    position: relative;
    height: 100%;
    padding: $space--7;
    background-color: $color--beige;
  }

  &__heading {
    max-width: 30ch;
    font-family: $font--verlag;
    font-size: $text--6;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.3;
  }

  &__link {
    color: $color--purple;
  }

  &__link + p {
    margin-top: $space--4;
  }

  &__icon {
    position: absolute;
    right: $space--6;
    bottom: $space--5;
    color: $color--blue;
  }

  &__supported {
    padding: 0 $space--7;
  }

  &__supported-content {
    display: flex;
    align-items: center;
    width: 100%;
    padding: $space--6 0;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
  }

  &__supported-heading {
    font-size: $text--1;
    font-weight: normal;
    color: $color--text-grey;
  }

  &__sponsors {
    display: flex;
    align-items: center;
  }

  &__sponsor {
    margin: 0 $space--7;
  }

  @add-mixin media s {
    &__image {
      display: block;
    }

    &__description {
      width: 50%;
    }

    &__icon {
      bottom: $space--7;
    }
  }
}
