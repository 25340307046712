.teaser {
  position: relative;
  padding: $space--7 $space--6;
  border-radius: $border-radius--2;
  color: #fff;
  background-color: $color--purple;
  overflow: hidden;

  > * {
    position: relative;
    z-index: 2;
  }

  &::before,
  &::after {
    display: none;
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: -100%;
    width: 500%;
    height: 100%;
    z-index: 1;
    transform: skewX(-30deg) translateX(100%);

    @add-mixin media s600 {
      display: block;
    }
  }

  &::before {
    right: 165px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  &::after {
    right: 65px;
    background-color: rgba(255, 255, 255, 0.3);
  }

  &__heading {
    margin-bottom: $space--2;
    font-size: $text--6;
    font-weight: 900;
  }

  &__text {
    max-width: 26rem;
  }

  &__label {
    margin-bottom: $space--2;
    font-weight: 700;
    font-size: $text--3;
    color: $color--teal;
  }

  &__link {
    @extend .button;
    @extend .button--teal;

    // Todo: ideally avoid button component overrides
    padding: $space--2 $space--6;
    white-space: nowrap;
  }

  &__lower {
    display: flex;
    align-items: center;
    margin-top: $space--5;
  }

  &__icon {
    display: none;
    position: absolute;
    bottom: $space--5;
    right: $space--5;

    @add-mixin media s600 {
      display: block;
    }
  }

  // currently only applies to tools
  &__membership {
    display: flex;
    align-items: center;
    margin-left: $space--4;
    color: #ff7575;
    font-size: $text--1;
    font-weight: 900;
    line-height: 1.5;
  }

  // currently only applies to tools
  &__membership-icon {
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    margin-right: $space--2;
  }

  // move background stripes to allow space for icon
  &--with-icon {
    &::before {
      right: 215px;
    }

    &::after {
      right: 115px;
    }
  }

  &--tools {
    @extend .teaser--with-icon;

    background-color: $color--purple;

    .teaser__label {
      color: $color--yellow;
    }

    .teaser__link {
      @extend .button--yellow;
    }
  }
}
