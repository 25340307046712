.divider {
  &::before {
    content: '';
    display: block;
    width: 90px;
    height: 4px;
    background-color: $color--teal;
    border-radius: $border-radius--1;
    margin-bottom: $space--5;
  }

  &--offset-small::before {
    margin-bottom: $space--2;
  }

  &--short::before {
    width: 50px;
  }

  &--yellow:before {
    background-color: $color--yellow !important;
  }

  &--orange:before {
    background-color: $color--orange !important;
  }

  &--unset-margin:before {
    margin-bottom: unset;
  }

  &--purple:before {
    background-color: $color--purple !important;
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--news-insights & {
    &:before {
      background-color: $color--purple;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--tools & {
    &:before {
      background-color: $color--yellow;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--news &,
  [class*='page--publication'] & {
    &:before {
      background-color: $color--orange;
    }
  }
}
