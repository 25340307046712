.dropdown {
  background-color: white;
  border: none;
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  font: inherit;
  font-size: $text--2;
  font-weight: $font-weight--4;
  padding: 0 $space--2;
  padding-right: 40px;
  margin: 0;
  cursor: pointer;
  color: $color--purple;
  height: 40px;
  width: 100%;
  max-width: 15rem;
  -webkit-appearance: none;
  -moz-appearance: none;

  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);

  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;

  background-size: 6px 5px, 5px 5px, 1px 1.5em;

  background-repeat: no-repeat;

  &::-ms-expand {
    display: none;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }

  &:focus {
    background-image: linear-gradient(45deg, currentColor 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, currentColor 50%);

    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;

    border-color: currentColor;
  }

  &--full-width {
    max-width: none;
    border-radius: 0;
    padding-left: $gutter;
  }

  &--change-width-training-events {
    max-width: none;
  }
  .page--training-and-events-listing & {
    position: absolute;
    right: 0px;

    @media (max-width: 1000px) {
      max-width: 100%;

      right: none;
      left: 0;
    }
  }
}
