.nav-sequential {
  padding: 1rem 0;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  &__prev,
  &__next {
    text-decoration: none;
    color: $color--blue;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: flex-end;

    & > * {
      padding: 0 0.2rem;
    }
  }

  &__next:nth-child(2)::before {
    content: '| ';
  }

  &__clarity-text {
    font-weight: normal;
    display: none;
  }

  @add-mixin media s {
    &__clarity-text {
      display: inherit;
    }
  }

  &__page-name {
    font-weight: bold;
    white-space: nowrap;
  }

  &__caret > * {
    vertical-align: middle;
    font-size: 1.75rem;
  }
}
