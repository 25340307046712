.collapsible {
  @extend .button, .button--blue;
  @extend .px-3, .py-2;
  font-size: $text--5;
  font-weight: 700;
  position: relative;
  z-index: 2;

  &__content {
    display: none;
    overflow: hidden;
    position: relative;
    z-index: 1;
    top: -57px; // height of the button
  }

  &.active:after {
    content: '\2212'; /* utc '-' symbol */
  }
}

.collapsible:after {
  @extend .ml-4;
  content: '\002B'; /* utc '+' symbol */
  font-size: $text--6;
  font-weight: 700;
  background-color: #fff;
  color: $color--blue;
  border: #fff solid 1px;
  border-radius: $border-radius--1;
  min-width: 40px;
}
