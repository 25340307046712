.steps-nav-sticky {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  color: $color--blue;
  font-size: $text--1;
  font-weight: 700;
  background-color: rgba(255, 255, 255, 0.85);
  overflow: hidden;
  z-index: 2;

  &__content {
    @extend .constrain-l;

    display: flex;
    justify-content: space-between;
  }

  &__step {
    display: flex;
    align-items: center;
    padding: $space--2 0;
  }

  &__step--prev {
    flex-direction: row-reverse;
  }

  &__icon {
    flex-shrink: 0;
  }

  &__step--prev &__icon {
    flex-direction: row-reverse;
    transform: scaleX(-1);
  }

  &__step--next {
    margin-left: auto;
  }

  &__text {
    display: block;
    margin: 0 $space--2;
  }

  &--sticky {
    opacity: 1;
  }

  &--change {
    opacity: 1;
  }

  &--stuck {
    opacity: 1;
    position: absolute;
  }

  &__parent {
    position: relative;
  }
}
