.nav-local {
  @extend .bg-purple;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: right;
  font-family: $font--museo;
  font-size: $text--2;
  font-weight: 700;

  @add-mixin media m {
    font-size: $text--3;
  }

  &__mobile-toggle {
    @extend .bg-purple;
    @extend .c-white;
    padding: $space--4 $space--2;
  }

  > button[aria-expanded='false'] {
    &::after {
      content: ' ';
      background-image: url(images/burger.svg);
      background-position: center;
      background-size: cover;
      width: $text--4;
      height: $text--4;
      display: inline-block;
      position: relative;
      height: 20px;
      top: 5px;
      left: 0;
      margin-left: $space--2;
    }
  }

  > button[aria-expanded='true'] {
    &::after {
      content: ' ';
      background-image: url(images/close.svg);
      background-position: center;
      background-size: cover;
      width: $text--4;
      height: $text--4;
      display: inline-block;
      position: relative;
      height: 20px;
      top: 5px;
      left: 0;
      margin-left: $space--2;
    }
  }

  &__list {
    @extend .bg-purple;

    position: absolute;
    z-index: 10;
    top: 64px; /* adds menu height*/
    left: 0;
    width: 100%;
    display: none;
    flex-direction: column;
    flex-wrap: wrap;
    min-height: $space--7;
    padding: $space--5;

    @add-mixin media m {
      display: flex;
      flex-direction: row;
      position: static;
      margin-top: $space--2;
      margin-bottom: $space--2;
      margin-left: calc($space--4 * -1);
      padding: 0;

      li {
        padding: $space--2 $space--4;
      }
    }
  }

  > [aria-expanded='true'] + ul {
    display: flex;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: $space--3;

    @add-mixin media m {
      padding: $space--4;
    }
  }

  &__link {
    display: block;
    color: #fff;
  }

  &__item--secondary {
    padding-top: $space--2;
    padding-bottom: $space--2;
  }
}
