.card {
  @extend .bg-white, .c-purple;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: $space--4;
  padding-right: $space--7;
  border-radius: $border-radius--2;
  transition: color $transition--default, background-color $transition--default;
  background-image: url('./icon-right--teal.svg');
  background-repeat: no-repeat;
  background-position: bottom $space--5 right $space--5; /* same as padding */
  cursor: pointer;

  &:focus-within {
    outline: currentColor auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: $space--1;
  }

  @add-mixin media s {
    padding: $space--5;
  }

  &--form {
    background-image: none;
  }
  &--triangle {
    &:after {
      content: '';
      width: 100%;
      height: 100%;
      right: 0;
      top: 0;
      z-index: 0;
      position: absolute;
      border-radius: $border-radius--2;
      background: linear-gradient(
        -135deg,
        $color--teal 40px,
        rgb(255 255 255 / 0%) 0
      );
    }

    & .card__date {
      color: $color--text-grey;

      &:hover {
        @extend .c-white;
      }
    }

    &--orange {
      background-image: url('./icon-right--orange.svg');

      &:after {
        background: linear-gradient(
          -135deg,
          $color--orange 40px,
          rgb(255 255 255 / 0%) 0
        );
      }
    }
  }

  &--bg-purple {
    @extend .bg-purple, .c-white;
    background-image: url('./icon-right--yellow.svg');

    & .card__resource-type {
      @extend .c-yellow;
    }

    .card--icon-external& {
      background-image: url('./icon-external--yellow.svg');
    }
  }

  &--highlight-orange {
    background-image: url('./icon-right--orange.svg');

    & .card__resource-type {
      @extend .c-orange-dark;
    }

    .card--icon-external& {
      background-image: url('./icon-external--orange.svg');
    }
  }

  &--no-icon {
    @extend .card;
    @extend .flex-column;
    flex-wrap: wrap-reverse;
    background-image: none;
    cursor: default;
  }
  &--constrained {
    min-height: $card-height--1;

    @add-mixin media s {
      min-height: $card-height--3;
      padding-top: $space--8;
      padding-bottom: $space--5;
    }
  }

  &--icon-external {
    background-image: url('./icon-external--teal.svg');
  }

  // Sub components
  &__header {
    @extend .text--4, .fw--5;
    font-family: $font--museo;

    @add-mixin media s {
      font-size: $text--5;
    }
  }

  &__header a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      // focus style is applied to .card root
      outline: none;
    }
  }

  &__body {
    @extend .text--1;
    margin: $space--2 0;

    > * + * {
      margin-top: $space--2;
    }

    @add-mixin media s {
      font-size: $text--2;
    }
  }

  &__footer {
    margin-top: auto;
    // 24px is the width of the icon
    padding-right: calc(24px + $space--1);
    font-size: $text--1;
    font-weight: $font-weight--3;
    justify-content: space-between;
    display: flex;
    flex-direction: column-reverse;
    gap: $space--5;

    @add-mixin media s600 {
      flex-direction: row;
    }

    &.column {
      @extend .stack;
      @extend .stack--space-3;
      padding-right: 0;
      justify-content: start;
      grid-gap: 0;
      gap: 0;

      @add-mixin media s600 {
        flex-direction: column;
      }
    }
  }

  &__sold-out {
    @extend .c-purple;
    width: fit-content;
    border-radius: $border-radius--2;
    background-color: $color--sand;
    font-weight: $font-weight--5;
  }

  &__resource-type {
    @extend .c-teal-darker;
    font-size: $text--1;
    font-weight: $font-weight--4;
  }

  &__sold-out-container {
    @extend .stack;
    @extend .stack--space-3;
    @extend .stack--space-between;
    width: min(360px, 55vw);
  }
  &__sold-out {
    @extend .c-purple;
    width: 100%;
    max-width: 124px;

    border-radius: $border-radius--2;
    background-color: $color--sand;
    font-weight: $font-weight--5;

    p {
      margin: 0 auto;
      font-size: $text--3;
      width: fit-content;
    }
  }

  &__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: $space--3;
  }

  &__external-link-text {
    @extend .c-teal-darker, .text-3;
    width: 75%;
    position: absolute;
    bottom: $space--5; // matches container padding to align text with the icon
    right: calc(
      $space--5 + 24px + $space--3
    ); // container paddding + icon size + padding
    display: flex;
    justify-content: flex-end;
  }

  // pseudo states
  &:not(.card--no-icon) {
    &:hover {
      text-decoration: none;
      background-image: url('./icon-right--purple.svg');
      color: $color--purple;
      background-color: $color--teal;

      .card--icon-external& {
        background-image: url('./icon-external--purple.svg');
      }

      .card--form& {
        background-image: none;
        background-color: white;
      }

      .card--triangle& {
        background-color: $color--purple;
        @extend .c-white;
        background-image: url('./icon-right--teal.svg');

        .card--triangle--orange& {
          background-image: url('./icon-right--orange.svg');
        }
      }

      * {
        color: inherit;
      }

      // Todo: avoid overriding other components
      .round-image:after {
        background-color: $color--teal;
        transition: $transition--default background-color;
      }
    }
  }
}
