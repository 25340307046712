.highlight-card {
  max-width: 390px; // image also needs to be 390px
  margin: auto;
  cursor: pointer;

  &:focus-within {
    outline: currentColor auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
  }

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover {
      text-decoration: none;
    }
  }

  &__image {
    border-radius: $border-radius--2 $border-radius--2 0 0;
    overflow: hidden;
    max-width: 390px; // image also needs to be 390px
  }

  &__content {
    flex-grow: 1;
    padding: $space--4;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 $border-radius--2 $border-radius--2;
    transition: background-color $transition--default;
  }

  &__header {
    color: $color--purple;
    @extend .heading, .heading--grouped;

    @add-mixin media s {
      font-size: $text--5;
    }
  }

  &__header a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      // focus style is applied to .card root
      outline: none;
    }
  }

  &__label {
    display: flex;
    align-items: center;
    margin-top: $space--4;
    font-weight: 500;
    color: $color--purple;

    &::before {
      content: '';
      display: block;
      height: 20px;
      width: 20px;
      background-color: $color--grey;
      border-radius: $border-radius--2;
      margin-right: $space--2;
    }
  }

  &:hover &__content {
    background-color: $color--teal;
  }
}
