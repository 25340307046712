$gap: $space--3;
$gap-m: $space--5;

// cross browser flex-based grid implementation
.layout-3col {
  display: flex;
  flex-wrap: wrap;
  margin: calc($gap * -1);
  overflow: hidden;

  // to ensure the content is positioned above the the triangles
  position: relative;

  > * {
    flex-grow: 1;
    margin: $gap auto;
    width: 100%;
  }

  // 2 column
  @add-mixin media s {
    > * {
      flex-basis: calc((100% / 2) - ($gap * 2));
      max-width: calc((100% / 2) - ($gap * 2));
      margin-left: $gap;
      margin-right: $gap;
    }
  }

  // 3 column
  @add-mixin media m {
    margin: calc($gap-m * -1);

    > * {
      margin: $gap-m;
      flex-basis: calc((100% / 3) - ($gap-m * 2));
      max-width: calc((100% / 3) - ($gap-m * 2));
    }
  }
}
