// New way of styling Cognito Forms - https://www.cognitoforms.com/support/165/style-publish/advanced-css-guide
:root .cog-cognito {
  --font-family: $font--museo;
  --font-size: $text--3;
  --color: $color--text-grey;
  --heading__font-family: $font--verlag;
  --line-height: 1.5;
  --input__border-radius: $border-radius--3;
  --input-focus__box-shadow: 0 0 1px 2px $color--teal;
  --input__background-color: $color--grey-light;
  --input__border-color: $color--grey-light;
  --a__color: $color--blue;
  --a__font-family: $font--museo;
  --button-primary__background-color: $color--blue;
  --button-primary__border-color: $color--blue;
  --button-primary__border-radius: $border-radius--2;
  --button-primary__color: $color--white;
  --button-primary__font-family: $font--museo;
  --button-secondary__font-family: $font--museo;
  --label__color: $color--purple;
  --label__font-family: $font--museo;
  --highlight: $color--teal;
  --form__width: 100%;
}

.cog-col {
  flex-grow: 1 !important;
}
