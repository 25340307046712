.landing-strip {
  color: $color--white;
  background-color: $color--purple;
  border-radius: $border-radius--2;
  display: flex;
  border-radius: 4px;
  flex-wrap: wrap-reverse;

  &__content {
    padding: $space--4;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    @add-mixin media s {
      padding: $space--7 $space--7 $space--5;
    }
  }

  &__header {
    font-size: $text--7;
    font-family: $font--verlag;
    font-weight: 900;
    line-height: 1.09;
    margin-bottom: $space--5;

    @add-mixin media s {
      font-size: $text--9;
    }
  }

  &__content-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__container {
    flex-grow: 1;
    flex-basis: 300px;
  }

  &__image {
    width: 100%;

    @add-mixin media s {
      flex-basis: 300px;
    }
    @add-mixin media m {
      flex-basis: 500px;
    }
  }

  img {
    height: 300px;
    width: 100%;
    object-fit: cover;

    @add-mixin media s {
      height: 100%;
      max-width: 100%;
      display: block;
      object-fit: cover;
    }
  }
}
