.toc {
  position: relative;
  margin-bottom: $space--7;

  &__heading {
    @extend .c-purple;

    margin-bottom: $space--2;
  }

  &__link {
    font-size: 18px;

    &--more-on-this-topic {
      font-weight: 500;
    }
  }

  &__items {
    margin-top: $space--5;
  }

  &__item {
    font-weight: 700;

    &:not(:last-of-type) {
      margin-bottom: $space--2;
    }

    &--secondary {
      font-weight: 300;
    }
  }

  &__icon {
    fill: currentColor;
    margin-right: $space--4;
  }
}
