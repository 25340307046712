$gap: $space--7;
$items-per-row: 3;

.tall-block {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 450px;
  color: inherit;
  line-height: 1.7;
  background-color: $color--sand;
  flex: 1;

  // assumes tall-block only appears in context of layout-3col
  height: 100%;

  &:hover {
    text-decoration: none;
  }

  &__image {
    width: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $space--4;
  }

  &__text {
    flex-grow: 1;
  }

  &__icon {
    margin-top: $space--4;
    margin-left: auto;
    color: $color--blue;
  }
}
