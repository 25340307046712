.tile-container {
  @extend .pt-6, .px-2, .pb-4;

  @add-mixin media s {
    padding: $space--7;
  }

  background-color: rgb(255, 255, 255, 0.8);
  border-radius: $border-radius--2;
  border: solid 1px #cfcfcf;
}

.tile-container li {
  @extend .mt-7;
  width: 100%;

  @add-mixin media s {
    margin-top: $space--6;
  }
}
