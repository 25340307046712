.icon-list {
  display: flex;
  flex-wrap: wrap;
  margin: calc($space--2 * -1) calc($space--4 * -1);
  margin-bottom: $space--5;
  font-size: $text--1;

  &__item {
    display: flex;
    align-items: center;
    margin: $space--2 $space--4;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__icon {
    max-height: 26px;
    margin-right: $space--2;
    color: $color--purple;
  }
}
