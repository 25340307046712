.nav-tree {
  @extend .divider;

  &:before {
    display: none;
  }

  @add-mixin media l {
    &:before {
      display: block;
    }
  }

  font-size: $text--1;
  margin-bottom: $space--4;

  &__heading {
    font-size: $text--3;
    font-weight: 900;
    color: $color--purple;
    margin-bottom: $space--4;
    padding-left: $space--4;
  }

  &__item {
    padding-left: $space--3;
    border-top: 1px solid $color--beige;

    &--active {
      background-color: $color--beige;
    }

    &--active .nav-tree__toggle {
      display: none;
    }

    &--active > .nav-tree__item-text {
      color: $color--purple;
      font-weight: 700;
    }

    &--closed .nav-tree__nested {
      display: none;
    }

    &:hover {
      background-color: $color--beige;
    }
  }

  &__item-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__nested {
    padding-left: $space--4;
  }

  &__link {
    display: block;
    flex-grow: 1;
    padding: $space--1 $space--2;
    color: inherit;
  }

  &__toggle {
    display: flex;
    align-self: flex-start;
    flex-shrink: 0;
    color: $color--blue;
    padding: $space--1 $space--2;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    color: $color--blue;

    // strip default button styles
    background: none;

    &:after {
      content: '\f067';
    }

    .nav-tree__item--open &:after {
      content: '\f068';
    }
  }

  &__toggle-icon {
    height: 10px;
    width: auto;
  }

  &__mobile-toggle {
    color: $color--purple;
    font-size: $text--2;
    font-weight: 900;
    background-color: transparent;
    float: right;
  }

  > ul {
    display: none;
  }

  > button[aria-expanded='true'] + ul {
    display: block;
  }

  @add-mixin media l {
    > ul {
      display: block;
    }
  }
}
