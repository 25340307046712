.training {
  background-color: $color--sand;
  width: 100%;
  padding-bottom: $space--4;

  .sub-title {
    background-color: $color--white;
    padding-top: $space--6;
    padding-bottom: $space--6;
    margin-bottom: $space--4;
  }

  .flex-box {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  @media (max-width: 1150px) {
    .flex-box {
      display: block;
    }
  }

  ul li:before {
    background-color: $color--teal;
  }
}

.quote-block {
  background-color: $color--white;
  padding: $space--6;
  border-radius: $border-radius--2;
}

.date {
  border-left: solid 4px $color--teal;
  color: $color--white;
  padding-left: $space--2;
  font-size: $text--2;

  p {
    font-size: $text--1;
  }
}

.tab {
  color: $color--teal;
  position: relative;

  &::before {
    content: '';
    display: none;
    position: absolute;
    left: -50px;
    width: 45px;
    height: 45px;
    border-radius: $border-radius--2;
    background: linear-gradient(
      135deg,
      $color--teal 30px,
      rgb(255 255 255 / 0%) 0
    );

    @add-mixin media s {
      display: block;
    }
  }
}
