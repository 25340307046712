.announcement-bar {
  border-bottom: 1px solid #eee;
  font-size: $text--3;
  color: $color--purple;
  background-color: #fff;
  font-weight: 500;
  // ensures content appears above any page background assets
  position: relative;
  z-index: 1;

  &__content {
    @extend .constrain-l;
    @extend .py-4;

    display: flex;
    align-items: center;
    flex-direction: column;

    @add-mixin media s {
      flex-direction: row;
    }
  }

  &__text {
    max-width: 980px;
  }

  &__link-text {
    text-decoration: underline;
  }

  &__label {
    align-self: center;
    text-transform: uppercase;
    font-weight: 900;
    font-family: $font--verlag;
    font-size: $text--2;
    padding: 0.5rem $space--3;
    margin-bottom: $space--2;
    background-color: #feb498;

    @add-mixin media s {
      margin-right: $space--4;
      margin-bottom: 0;
      align-self: flex-start;
    }
  }
}

.bereavement-announcement-bar {
  border-bottom: 1px solid #eee;
  font-size: $text--3;
  color: $color--purple;
  background-color: black;
  font-weight: 500;
  // ensures content appears above any page background assets
  position: relative;
  z-index: 1;

  &__content {
    @extend .constrain-l;
    @extend .py-4;

    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: black;

    @add-mixin media s {
      flex-direction: row;
    }
  }

  &__text {
    max-width: 980px;
    color: white;
  }

  &__link-text {
    text-decoration: underline;
    color: white;
  }

  &__label {
    align-self: center;
    text-transform: uppercase;
    font-weight: 900;
    font-family: $font--verlag;
    font-size: $text--2;
    padding: 0.5rem $space--3;
    margin-bottom: $space--2;
    background-color: white;
    color: black;

    @add-mixin media s {
      margin-right: $space--4;
      margin-bottom: 0;
      align-self: flex-start;
    }
  }
}
