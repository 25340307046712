.text-image-block {
  color: $color--purple;
  font-size: $text--4;
  line-height: 1.7;
  background-color: $color--sand;

  &__heading {
    margin-bottom: $space--4;
    max-width: 30ch;
    font-family: $font--verlag;
    font-size: $text--6;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.3;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: $space--6;
    flex: 1;
  }

  &__image {
    align-self: flex-start;
    width: 100%;
    max-width: 750px;
    text-align: right;
  }

  &__link {
    color: $color--purple;
  }

  &__icon-link {
    position: absolute;
    color: $color--blue;
    bottom: $space--6;
    right: $space--6;
  }

  &__supported {
    padding: 0 $space--7;
  }

  &--image-left &__image {
    order: -1;
    text-align: left;
  }

  @add-mixin media s {
    &__content {
      flex-direction: row;
    }

    &__image {
      width: 50%;
    }
  }

  @add-mixin media l {
    &__text {
      padding: $space--7;
    }
  }
}
