@import '../button/button';

.back-to-top {
  @extend .button;
  @extend .button--blue;

  z-index: 2;
  position: absolute;
  bottom: $space--7;
  margin-left: $space--4;
  display: block;
  transition: opacity 0.2s ease-in-out;
  opacity: 0;

  &--change {
    opacity: 1;
  }

  &--stuck {
    margin-bottom: $space--4;
    opacity: 1;
  }

  &__parent {
    position: relative;
  }

  @media print {
    display: none;
  }
}
