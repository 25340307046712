.breadcrumb-dropdown {
  position: relative;
  left: calc($gutter * -1);
  width: calc(100% + ($gutter * 2));
  background-color: transparent;

  @add-mixin media s {
    left: 0;
    width: auto;
  }

  &__label {
    display: block;
    padding: $space--2 $space--4;
    width: 100%;
    border: 0;
    font-size: $text--1;
    font-weight: 700;
    text-align: left;
    color: currentColor;
    cursor: pointer;

    // override base button styles
    background: transparent;

    &:hover {
      background: transparent;
    }

    &:focus {
      outline: 0;
    }

    &.active {
      ^&__icon {
        transform: rotate(180deg);
      }
    }

    a,
    a:visited {
      color: currentColor;
    }

    @add-mixin media s {
      padding: $space--1 0;
      width: auto;
      font-size: $text--2;
    }
  }

  &__icon {
    vertical-align: middle;
    margin-left: 3px;
    transition: $transition--default transform;
  }

  &__list {
    display: none;
    opacity: 0;
    position: absolute;
    background: #fff;
    z-index: 1;
    left: 0;
    padding: $space--2 0;
    box-shadow: -2px 10px 14px rgba(0, 0, 0, 0.15);
    transition: $transition--default opacity, $transition--default transform;
    max-height: 200px;
    overflow-y: auto;

    &.block {
      display: block;
    }

    &.active {
      opacity: 1;
      transform: translateY(0);
    }

    @add-mixin media s {
      left: calc($space--4 * -1);
      min-width: 300px;
      max-width: 460px;
      border-radius: 5px;
    }
  }

  &__item {
    padding: $space--2 $space--4;
    font-size: $text--1;

    @add-mixin media s {
      font-weight: 700;
    }

    &--current-page {
      color: $color--grey;
    }
  }

  &__link {
    &,
    &:visited {
      color: $color--blue;
      font-weight: 300;

      @add-mixin media s {
        font-weight: 700;
      }
    }

    &--parent {
      &,
      &:visited {
        color: $color--purple;
        font-size: $text--2;
        font-weight: 700;
      }
    }
  }
}
