.long-answer {
  font-size: $text--2;
  font-weight: bold;
  color: $color--purple;
  line-height: $text--3;
  display: block;
  margin: $space--4 0 0 0;

  &__input {
    font-weight: normal;
    min-width: 100%;
    font-size: $text--2;
    line-height: 1.4;
    margin-top: $space--2;
    resize: vertical;
    height: 100px;
    padding: $space--1;
  }
}
