.connected-list {
  position: relative;
  font-size: $text--2;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: $space--2;
    width: 2px;
    height: calc(100% - $space--2);
    background-color: $color--teal;
  }

  &__item {
    position: relative;
    padding-top: $space--1;
    padding-bottom: $space--1;
  }

  &__link {
    display: block;
    padding-left: $space--5;

    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 3px;
      top: 0;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      border: 2px solid #fff;
      background-color: #89cbc0;
    }
  }
}
