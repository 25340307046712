.info-tile {
  max-width: 330px;
  &__title {
    @extend .my-2;
    color: $color--purple;
    font-family: $font--museo;
    font-weight: 900;
    font-size: $text--5;
  }

  &__summary {
    font-size: $text--2;
    font-weight: 500;
  }

  &__decorative-line {
    width: 48px;
    height: 4px;
    margin: 0 245px 18px 0;
    transform: rotate(-360deg);
    border-radius: $border-radius--1;
    background-color: #ffb395;
  }

  &__link {
    @extend .link;
    font-size: $text--2;
    font-weight: 500;
  }

  &--number-block-width {
    max-width: 290px;
  }
}
