.image-block {
  margin-top: $space--7;
  margin-bottom: $space--7;

  &__image {
    height: auto;
    max-width: 100%;
  }

  &__caption {
    margin-top: $space--2;
    font-size: $text--1;
  }
}
