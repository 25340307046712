.profile-heading {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: $space--4;
  margin-bottom: $space--4;

  &__icon {
    flex-shrink: 0;
  }

  &__welcome {
    display: flex;
    align-items: center;
    margin-right: auto;
    line-height: 1;
  }

  &__logout-column {
    display: none;
    flex-shrink: 0;
    margin-left: auto;
  }

  &__right {
    flex-shrink: 0;
    display: flex;
    margin-top: $space--4;
  }

  &__sub-heading {
    background-color: transparent;
    font-family: $font--museo;
    font-weight: bold;
    font-size: $text--5;
    color: $color--purple;
  }

  @add-mixin media s {
    flex-direction: row;

    &__logout-column {
      display: block;
    }

    &__logout-button-mobile {
      display: none !important;
    }
  }
}

.ncvo-member-badge {
  height: 113px;
  border-radius: 5px;
}
