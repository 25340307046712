.breadcrumb {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: $space--4;
  font-size: $text--1;
  color: $color--blue;

  // pushes flex item on to next line
  &__break {
    flex-basis: 100%;
    height: 0;
  }

  &__item {
    position: relative;
    padding-right: 25px;
    white-space: nowrap;

    &::after {
      content: '';
      position: absolute;
      right: 5px;
      top: 0;
      bottom: 0;
      margin: auto 0;
      width: 16px;
      height: 2px;
      border-radius: $border-radius--1;
      transform: rotate(-58deg);
      background-color: $color--teal;
    }
  }

  &__item:last-child {
    font-weight: 700;
  }

  &--template {
    color: #fff;
  }

  &__link {
    color: currentColor;
  }

  &--alternative &__item:after {
    background-color: #fff;
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--news &,
  .page--training-and-events &,
  .page--news-index & {
    .breadcrumb__item:after {
      background-color: #ffb395;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--tools & {
    .breadcrumb__item:after {
      background-color: $color--yellow;
    }
  }

  .page--tools &,
  [class*='publication'] & {
    position: relative;
    padding: $space--2 0;
    color: #fff;
    // offset .hero spacings
    margin: -40px 0 40px;

    &::before {
      position: absolute;
      background: rgba(59, 30, 77, 0.9);
      content: '';
      top: 0;
      left: -100vw;
      width: 200vw;
      height: 100%;
    }

    .nav__item--breadcrumb {
      position: relative;
      color: #fff;
    }
  }

  .page--publication &,
  .page--publication-homepage & {
    margin-top: -60px;
  }

  [class*='publication'] & {
    .breadcrumb__item:after {
      background-color: $color--orange;
    }
  }

  .page--tools & {
    margin: -80px 0 40px;
  }
}
