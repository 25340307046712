.sponsors {
  display: flex;
  flex-wrap: wrap;
  padding: $space--5 0;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  flex-direction: column;

  @add-mixin media s {
    flex-direction: row;
  }

  &__heading {
    margin-top: calc($space--2 * -1);
    margin-right: $space--4;
    padding: $space--2 0;
    font-size: $text--1;
    font-weight: normal;
    color: $color--text-grey;
    white-space: nowrap;
  }

  &__content {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: calc($space--2 * -1);
  }

  &__image {
    margin: $space--2;
  }
}

.sponsor-grid {
  display: grid;
  grid-template-columns: fit-content(100%) fit-content(100%) fit-content(100%) fit-content(
      100%
    ) fit-content(100%);
  gap: 52px;

  @media (min-width: 501px) and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
    align-self: center;
  }

  @add-mixin media s500 {
    grid-template-columns: 1fr;
    align-self: center;
    width: fit-content;
  }
}
