.tools {
  padding: $space--8 0;
  color: #fff;
  background-color: $color--purple;

  .hero__heading {
    margin-bottom: $space--4;
    font-size: $text--9;
    font-weight: 900;
    line-height: 1.25;
  }

  .hero__button {
    color: $color--purple;
    background-color: $color--yellow;
  }

  .hero__button:hover {
    background-color: $color--white;
  }

  .hero__membership,
  .hero__free {
    color: $color--white;
  }

  .hero_member-content-p {
    color: $color--yellow;
  }

  .divider {
    @extend .divider--offset-small;
  }

  // Todo: avoid overriding other components
  .share {
    margin-top: $space--7;
  }

  // Todo: avoid overriding other components
  .share__heading {
    color: #fff;
  }

  // Todo: avoid overriding other components
  .layout-default__left {
    margin-top: 0;
    order: -2;
  }
}

.hero {
  @extend .text-3, .text-sm-4;

  line-height: 1.4;
  color: $color--purple;
  font-weight: 500;

  @add-mixin media s {
    line-height: 1.7;
  }

  &__introduction {
    margin-top: $space--2;
  }

  &__content {
    border-radius: $border-radius--2;

    // ensures content appears above any page background assets
    position: relative;
  }

  &__meta {
    display: flex;
    justify-content: space-between;
    margin-top: $space--4;
    padding-top: $space--4;
    border-top: 1px solid #f1f2f0;
    font-size: $text--1;
  }

  &__meta-content {
    // IE11 fix, https://stackoverflow.com/a/35113633
    width: 100%;
  }

  // currently only applies to tools
  &__label {
    margin-bottom: $space--4;
    color: $color--yellow;
    font-size: $text--5;
    font-weight: 700;
    line-height: 1;

    .hero__heading + & {
      order: -1;
    }
  }

  // currently only applies to tools
  &__icon {
    align-self: flex-start;
    margin-bottom: $space--6;

    @add-mixin media l {
      align-self: flex-end;
      margin-bottom: 0;
    }
  }

  // currently only applies to tools
  &__membership,
  &__free {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: $space--2;
    color: #ff7575;
    font-size: $text--1;
    font-weight: 900;
    line-height: 1.5;
  }

  &__free {
    color: currentColor;
  }

  // currently only applies to tools
  &__membership-icon,
  &__free-icon {
    height: 40px;
    width: 40px;
  }

  .page--steps-index &,
  .page--hg-guidance &,
  .page--hg-index & {
    &__member-content {
      min-height: 70vh;
    }

    .hero__button {
      color: $color--teal;
      background-color: $color--purple;
    }
    .hero__button:hover {
      background-color: $color--teal;
      color: $color--purple;
    }

    .hero__button > span {
      color: $color--white;
    }

    .hero__button:hover > span {
      color: $color--purple;
    }

    .hero_member-content-p {
      color: $color--purple;
    }

    &__membership,
    &__free {
      color: $color--purple;
    }
  }

  .page--steps-index & {
    overflow: hidden;
    background-color: $color--teal;

    .hero__button:hover {
      background-color: $color--white;
      color: $color--purple;
    }

    .hero__heading {
      @extend .heading--important;
      font-size: 2.3em;
    }

    .hero__content {
      @extend .py-9;
    }

    @add-mixin media s {
      &:before {
        content: '';
        position: absolute;
        top: -35px;
        left: -125px;
        width: 318px;
        height: 380px;
        background-color: $color--teal-light;
        transform: skew(-30deg);
      }
    }
  }

  .page--steps-campaigns & {
    overflow: hidden;
    background-color: $color--orange;

    .hero__heading {
      hyphens: auto;
      word-break: break-word;
      @extend .heading--important;
      font-size: 2.3rem;
    }

    .hero__content {
      @extend .py-9;
    }

    @add-mixin media s {
      &:before {
        content: '';
        position: absolute;
        top: -35px;
        left: -210px;
        width: 318px;
        height: 380px;
        background: linear-gradient(
          270deg,
          $color--orange-light 55px,
          $color--yellow 0
        );
        transform: skew(-30deg);
      }
    }
  }

  .page--publication &,
  .page--publication-homepage &,
  .page--gated-content &,
  .page--publication-section & {
    padding: $space--7;
    background-image: url('./images/stripes.svg');
    background-position: center;
    background-size: cover;

    .hero__heading {
      @extend .heading--important;
      font-size: $text--6;

      @add-mixin media s {
        font-size: $text--9;
      }
    }

    .hero__content {
      @extend .p-4, .p-s-7;

      margin-left: calc($space--7 * -1);
      margin-right: calc($space--7 * -1);
      background-color: rgba(255, 255, 255, 0.85);
    }
  }

  .page--gated-content & {
    height: 100%;
    padding-bottom: 0;
    width: 100%;
    background-image: none;

    .hero__content {
      @extend .page--publication;
      z-index: 1;
      flex-wrap: wrap;
    }

    .hero__sponsor-label {
      margin-bottom: $space--2;
    }

    .hero__sponsor-image {
      max-width: 192px;
    }

    .hero__content-container {
      flex: 1;
    }
  }

  .page--information & {
    background-image: url('./images/pattern.png');
    background-position: center;
    background-size: cover;

    .hero__heading {
      @extend .heading--primary;
    }

    .hero__content {
      @extend .p-4, .p-s-7, .mt-8;

      padding-bottom: 0;
      border-radius: $border-radius--2 $border-radius--2 0 0;
      background-color: #fff;
      margin-left: calc($space--4 * -1);
      margin-right: calc($space--4 * -1);

      @add-mixin media s {
        margin-left: calc($space--7 * -1);
        margin-right: calc($space--7 * -1);
      }
    }
  }

  .page--publication-section & {
    padding: $space--6 $space--7;
    font-size: $text--2;

    .hero__heading {
      font-family: $font--verlag;
      font-size: $text--6;
      color: $color--purple;
      text-transform: uppercase;
      font-weight: 900;
      line-height: 1.25;
    }

    .hero__introduction {
      margin-top: 0;
    }

    .hero__content {
      @extend .py-4, .py-s-6, .px-4, .px-s-7;
    }
  }

  .page--hg-index &,
  .page--publication-listing &,
  .page--campaign-index &,
  .page--person-index & {
    .hero__heading {
      @extend .heading--important;
      font-size: $text--6;

      @add-mixin media s {
        font-size: $text--9;
      }
    }

    .hero__content {
      @extend .pt-7, .pt-s-8, .pb-7;
    }
  }

  .page--hg-guidance &,
  .page--campaign-guidance & {
    .hero__heading {
      @extend .heading--primary;
    }

    .hero__content {
      @extend .py-6, .pt-s-6, .pt-s-8;
    }
  }

  .page--news-index &,
  .page--training-and-events-listing & {
    .hero__heading {
      @extend .heading--important;
    }

    .hero__content {
      @extend .pt-7, .pt-s-8, .pb-6;
    }
  }

  .page--news & {
    .hero__heading {
      @extend .heading--primary;
      @extend .mb-6;
    }

    .hero__content {
      @extend .pt-7, .pb-7;
    }
  }

  .page--tools &,
  .page--news-insights &,
  .page--tools-index & {
    padding: $space--8 0;
    color: #fff;
    background-color: $color--purple;

    .hero__heading {
      hyphens: auto;
      word-break: break-word;
      margin-bottom: $space--4;
      font-size: $text--9;
      font-weight: 900;
      line-height: 1.25;
    }

    // Todo: avoid overriding other components
    .divider {
      @extend .divider--offset-small;
    }

    // Todo: avoid overriding other components
    .share {
      margin-top: $space--7;
    }

    // Todo: avoid overriding other components
    .share__heading {
      color: #fff;
    }

    // Todo: avoid overriding other components
    .layout-default__left {
      margin-top: 0;
      order: -2;
    }
  }

  .page--news-insights &,
  .page--tools-index & {
    position: relative;
    color: $color--purple;
    background-color: #ffb395;
    overflow: hidden;

    // Todo: avoid overriding other components
    .layout-default {
      // appear above triangles
      position: relative;
      z-index: 1;
      language: 'en';
    }

    .layout-default__left {
      margin-top: auto;
    }

    // Todo: avoid overriding other components
    .share {
      margin-top: 0;
    }

    // Todo: avoid overriding other components
    .share__heading {
      color: inherit;
    }

    // Todo: avoid DOM dependency and overriding other components
    .layout-default__left > .round-image:after {
      border-color: currentColor;
      background-color: #ffb395;

      height: 4px;
      width: 50px;
      bottom: 20px;
      right: -15px;
    }

    // triangles
    &:after,
    & + .page--news-insights__triangle:after,
    & + .page--tools-index__triangle:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      display: none;

      @add-mixin media l {
        display: block;
      }
    }

    &:after {
      bottom: 0;
      right: -260px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #ffc2aa;
      border-width: 0 0 885px 510px;
    }

    & + .page--news-insights__triangle:after,
    & + .page--tools-index__triangle:after {
      bottom: -100px;
      right: 0;
      border-width: 0 0 400px 230px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--purple;
    }
  }

  .page--tools-index & {
    min-height: 400px;
    padding-top: 120px;
    background-color: $color--teal;

    &:after {
      border-bottom-color: $color--teal-light;
    }

    .hero__heading {
      text-transform: uppercase;
    }
  }

  // hexagon shape in top left
  &__hexagonals {
    &:before {
      content: '';
      display: none;
      position: absolute;
      top: -40px;
      left: -160px;
      width: 318px;
      height: 380px;
      background-color: $color--orange-light;
      transform: skew(-30deg);
      @add-mixin media s {
        display: block;
      }
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      top: -130px;
      left: -160px;
      width: 318px;
      height: 380px;
      background-color: $color--orange;
      transform: skew(-30deg);
      @add-mixin media s {
        display: block;
      }
    }

    &--small {
      @extend .hero_hexagonals;

      &:before {
        top: -180px;
        left: -90px;
      }

      &:after {
        top: -220px;
        left: -120px;
      }
    }

    &--teal {
      @extend .hero_hexagonals;

      &:before {
        background-color: $color--teal-60;
      }

      &:after {
        background-color: $color--teal;
      }
    }
  }

  .page--news-and-insights-landing & {
    position: relative;
    background-color: $color--sand;
    overflow: hidden;

    .hero__content {
      // appear above triangles
      position: relative;
      z-index: 1;
    }

    // triangles
    &:after,
    & + .page--news-and-insights-landing__triangle:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      bottom: 0;
      right: -260px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--orange-light;
      border-width: 0 0 885px 510px;
    }

    & + .page--news-and-insights-landing__triangle:after {
      bottom: -100px;
      right: 0;
      border-width: 0 0 400px 230px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--orange;
    }
  }

  .page--training-and-events & {
    position: relative;
    background-color: $color--purple;
    overflow: hidden;
    padding-top: $space--7;
    padding-bottom: $space--7;

    .hero__content {
      // appear above triangles
      position: relative;
      z-index: 1;
    }

    // triangles
    &:after,
    & + .page--training-and-events__triangle:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      bottom: 0;
      right: -250px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--blue-light;
      border-width: 0 0 885px 510px;
    }
  }

  .page--search-results & {
    position: relative;
    overflow: hidden;

    .hero__content {
      // appear above triangles
      position: relative;
      z-index: 1;
    }

    // triangles
    &:after,
    & + .page--search-results__triangle:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
    }

    &:after {
      display: none;
      bottom: 0;
      right: -260px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(81, 41, 106, 0.2);
      border-width: 0 0 885px 510px;

      @add-mixin media s {
        display: block;
      }
    }

    & + .page--search-results__triangle:after {
      display: none;
      bottom: -100px;
      right: 0;
      border-width: 0 0 400px 230px;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) $color--purple;

      @add-mixin media s {
        display: block;
      }
    }
  }
}
