.stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: $text--4;
  color: $color--purple;

  &--link {
    padding: $space--4;
    background-color: $color--yellow;

    &:hover {
      text-decoration: none;
    }

    .stat__content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    .stat__value,
    .stat__valueTwo {
      font-size: $text--5;
      font-weight: 900;
      line-height: 1.3;
      word-break: break-word;
      flex-shrink: 0;
      font-family: $font--verlag;
    }

    .stat__value {
      text-transform: uppercase;
    }

    .stat__valueTwo {
      text-transform: lowercase;
      font-weight: 700;
    }

    .stat__summary {
      margin-top: $space--3;
      margin-left: auto;
    }

    .stat__col {
      margin-left: 0;

      &:first-of-type {
        margin-left: 0;
      }
    }

    .stat__icon {
      display: none;
    }

    @add-mixin media s {
      padding: $space--6;

      .stat__value,
      .stat__valueTwo {
        font-size: $text--9;
      }
      .stat__icon {
        display: block;
        flex-shrink: 0;
        margin-left: $space--7;
      }
      .stat__content {
        flex-direction: row;
        align-items: center;
      }

      .stat__value {
        max-width: 60%;
        margin-right: $space--7;
      }

      .stat__summary {
        margin-top: 0;
      }

      .stat__col {
        margin-left: auto;
      }
    }

    @add-mixin media xl {
      .stat__summary {
        margin-right: auto;
      }
    }
  }

  &--aside {
    padding: $space--4;
    background-color: $color--grey-light;
    border: 0px solid $color--grey-light;
    border-left-width: 5px;
    border-left-color: $color--yellow;

    .stat__value {
      font-family: $font--verlag;
      font-size: $text--4;
      font-weight: 900;
      font-style: normal;

      &:after {
        content: ' ';
        white-space: pre;
      }
    }

    // Todo: avoid page-specific variants by moving to themable components
    [class*='page--news'] &,
    [class*='page--publication'] & {
      border-left-color: $color--orange;
    }

    // Todo: avoid page-specific variants by moving to themable components
    [class*='page--hg'] & {
      border-left-color: $color--teal;
    }
  }
}
