.chip {
  display: inline-flex;
  align-items: center;
  min-height: 21px;
  border-top-left-radius: $border-radius--2;
  border-bottom-left-radius: $border-radius--2;
  font-size: $text--1;
  font-weight: 500;
  line-height: 1.25;
  position: relative;
  padding: 2px $space--2;
  margin-right: $space--3;

  > * {
    position: relative;
    z-index: 1;
  }

  &::last-child {
    margin-right: 0;
  }

  &::after {
    content: '';
    display: inline-block;
    top: 0;
    right: -15px;
    width: 50%;
    height: 100%;
    position: absolute;
    border-radius: $border-radius--2;
    background-color: #fff;
    transform: rotate(180deg) skew(-28deg, 0deg);
  }

  &--white {
    background-color: #fff;
    color: $color--purple;

    &::after {
      background-color: #fff;
    }
  }

  &--grey {
    background-color: $color--grey-light;
    color: $color--purple;

    &::after {
      background-color: $color--grey-light;
    }
  }
}
