.consent-block {
  position: relative;
  z-index: 2;

  &--description {
    max-width: 100%;
    font-size: $text--4;
    line-height: 1.5;
  }

  &--checkbox-button-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: $space--6;
    row-gap: $space--3;

    &__member {
      @extend .consent-block--checkbox-button-container;
      flex-wrap: wrap;
      justify-content: flex-end;
    }
  }

  &--form {
    text-wrap: wrap;
    justify-content: center;
  }

  &--button-width-constrain {
    max-width: 530px;
    width: 100%;
    flex: 1;
  }

  &--privacy-link {
    color: $color--white;
    text-decoration: underline;
    font-weight: 400;
    display: block;
    margin-top: $space--3;
  }
}
