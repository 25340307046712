.acknowledgement {
  display: flex;
  justify-content: right;
  padding-top: 3em;
  margin: 0;
  align-items: baseline;
  align-content: baseline;

  &--text {
    font-weight: normal;
    font-size: 85%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 100%;
      bottom: 0.8em;
      width: 26px;
      height: 4px;
      border-radius: $border-radius--2;
      background-color: $color--purple;
      transform: rotate(-58deg);
    }
  }
}

.share-text {
  font-weight: normal;
  padding-top: 1em;
  font-size: 75%;
}
