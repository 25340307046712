.footnotes {
  @extend .divider;
  @extend .divider--offset-small;

  margin-top: $space--7;

  &__heading {
    margin-bottom: 1.5em;
    font-size: $text--3;
    color: $color--purple;
  }

  &__content {
    @extend .rich-text;
    @extend .rich-text--alternative-numbers;
    @extend .text-1;
  }

  // CSS scoping exception!
  //
  // in practice, the rich-text region is nested
  .rich-text {
    // ensures that the footnote and return to content link are displayed inline
    display: inline;

    p {
      display: inline;
    }
  }
}
