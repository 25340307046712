.global-promo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: $space--6;
  padding-bottom: $space--6;
  font-size: $text--4;
  color: $color--purple;
  background-color: $color--yellow;
  transition: background-color $transition--default;

  // ensures content appears above any page background assets
  position: relative;

  &:hover {
    text-decoration: none;
    background-color: $color--teal;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__main {
    @extend .constrain-l;

    display: flex;
    align-items: center;
  }

  &__summary {
    flex-grow: 1;
  }

  &__icon {
    flex-shrink: 0;
    margin-bottom: $space--3;
  }

  &__heading {
    font-family: $font--verlag;
    font-size: $text--7;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: $space--2;
    margin-left: auto;
  }

  &__icon-advance {
    flex-shrink: 0;
    margin-left: $space--7;
  }

  &__description {
    max-width: none;
  }

  @add-mixin media s {
    &__content {
      flex-direction: row;
      align-items: center;
    }

    &__icon {
      margin-right: $space--6;
      margin-bottom: 0;
    }
  }
}
