.search-input {
  font-weight: $font-weight--4;
  font-size: $text--2;

  &__field {
    position: relative;
    width: 100%;
    max-width: 275px;
    color: $color--purple;
  }

  &__form {
    // remove browser defaults
    margin: 0;
  }

  &__label {
    display: block;
  }

  &__input {
    height: 40px;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    padding-left: $space--2;

    // avoid collision with submit
    padding-right: 40px;

    // remove browser defaults
    border: none;

    &::-webkit-input-placeholder {
      color: $color--purple;
    }
    &::-moz-placeholder {
      color: $color--purple;
    }
    &:-ms-input-placeholder {
      color: $color--purple;
    }
    &:-moz-placeholder {
      color: $color--purple;
    }
  }

  &__submit {
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    // remove browser defaults
    padding: 0;
    background: none;
  }

  &__toggle {
    display: inline-flex;
    align-items: center;
    color: #fff;
    height: 20px;
    font-size: $text--3;

    // remove browser defaults
    padding: 0;
    background: none;
  }

  &__toggle > svg {
    margin-left: $space--2;
  }
}

.search-input-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(81, 41, 106, 0.8);
  }

  &__content {
    position: relative;
    padding: $space--2;
    border-radius: $border-radius--3;
    background-color: $color--sand;
  }

  &__close-container {
    display: flex;
  }

  &__close {
    margin-bottom: $space--2;
    margin-right: $space--1;
    margin-left: auto;

    // remove browser defaults
    padding: 0;
    background: none;
  }
}
