.video-block {
  display: flex;
  flex-direction: column;
  flex: 1 1 0px;

  &__image {
    border-radius: $border-radius--2 $border-radius--2 0 0;
    overflow: hidden;
  }

  &__content {
    background: rgba(255, 255, 255, 0.8);
    border-radius: 0 0 $border-radius--2 $border-radius--2;
    font-size: $text--2;
    color: $color--text-grey;
    @extend .p-4;
  }

  &__source {
    position: relative;
    padding-left: 2em; /*width as before element*/

    // copied from quote content component
    &::before {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 2em;
      height: 4px;
      border-radius: $border-radius--2;
      background-color: $color--teal;
      transform: rotate(-58deg);
    }
  }
}

.block-video-container {
  margin-right: 20%;
}
