.join-strip {
  color: #fff;
  background-color: $color--purple;
  max-width: $constrain--l;
  width: 100%;
  border-radius: $border-radius--2;
  overflow: hidden;

  &__content {
    padding: $space--4;

    @add-mixin media s {
      padding: $space--7;
    }
  }

  &__header {
    font-size: $text--7;
    font-family: $font--verlag;
    font-weight: 900;
    line-height: 1.09;
    margin-bottom: $space--5;

    @add-mixin media s {
      font-size: $text--9;
    }
  }

  &__paragraph {
    font-size: $text--2;
    font-weight: 500;
    line-height: 1.7;
    margin-bottom: $space--5;
    max-width: 780px;

    @add-mixin media s {
      font-size: $text--4;
    }
  }

  &__image {
    height: 0;
    padding-bottom: 84%;
    width: 100%;
    background-image: url('./join_ncvo.svg');
    background-repeat: no-repeat;
    background-size: 303%;
    background-position: 15% 31%;

    @add-mixin media sm {
      height: 100%;
      padding: 0;
    }
  }
}
