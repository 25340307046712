.roundup {
  &__heading {
    @extend .heading;
    @extend .heading--secondary;
  }

  &__content {
    @extend .rich-text;
    @extend .rich-text--alternative-numbers;
    @extend .rich-text--large-numbers;

    margin-top: $space--6;
  }

  // Todo: shouldn't be necessary but .button icon variants force them to be full width
  &__controls {
    display: inline-block;
    margin-top: $space--6;
  }
}
