.round-image {
  position: relative;
  display: inline-block;

  &__image {
    border-radius: 50%;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 10%;
    right: -35%;
    width: 85%;
    height: 25%;
    border-radius: $border-radius--1;
    transform: rotate(-58deg);
    background-color: #fff;
    border-top: 4px solid $color--teal;
  }

  // apply when image is greater than 100x100 px
  &--large:after {
    width: calc(100% - 20px);
    bottom: 15%;
    border-width: 8px;
  }

  &--orange:after {
    border-top-color: $color--orange !important;
  }

  // Todo: avoid page-specific variants by moving to themable components
  [class*='publication'] & {
    &:after {
      border-top-color: $color--orange;
    }
  }
}
