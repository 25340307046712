$arrow-size: 20px;
$line-height: 1.5;

.author {
  position: relative;

  &__details {
    min-width: 280px;

    // displayed on hover/focus, when within .author-list__item--has-details
    display: none;
  }

  &__content {
    display: flex;
    flex-direction: row-reverse;
    min-width: 200px;
    max-width: 350px;
    padding: $space--4;
    border-radius: $border-radius--2;
    background-color: #fff;
    box-shadow: 0 20px 20px 0 rgba(0, 0, 0, 0.08);
  }

  &__about {
    flex-grow: 1;
  }

  &__image {
    align-self: flex-start;
    flex-shrink: 0;
    margin-right: $space--4;
    width: 50px;
  }

  &__name {
    color: $color--purple;
    font-size: $text--2;
  }

  &__job-title {
    color: $color--purple;
    font-weight: normal;
  }

  &__author-content {
    margin-top: $space--2;
  }

  &__more-link {
    color: $color--blue;
    float: right;
    margin-top: $space--2;
    margin-left: $space--2;
  }

  &__details-arrow,
  &__details-arrow::before {
    position: absolute;
    width: $arrow-size;
    height: $arrow-size;
    background: #fff;
  }

  &__details-arrow {
    visibility: hidden;
  }

  &__details-arrow::before {
    visibility: visible;
    content: '';
    transform: rotate(45deg);
  }

  &__details[data-popper-placement^='bottom'] > .author__details-arrow {
    top: calc(($arrow-size / 2) * -1);
  }

  &__details[data-popper-placement^='left'] > .author__details-arrow {
    right: calc(($arrow-size / 2) * -1);
  }

  &__details[data-popper-placement^='right'] > .author__details-arrow {
    left: calc(($arrow-size / 2) * -1);
  }
}

.author--has-details {
  &::before {
    content: '';
    display: block;
    height: $arrow-size;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
  }

  &:hover {
    text-decoration: underline;
  }

  // positioning controlled by popperjs library
  &:hover .author__details,
  .author__trigger:focus + .author__details,
  .author__details[data-show] {
    display: block;
    z-index: 5;
  }
}

.author-list {
  display: flex;
  flex-wrap: wrap;

  &__item {
    display: flex;
    line-height: $line-height;
    position: relative;
  }

  &__item:not(:last-child)::after {
    content: ', ';
    white-space: pre;
  }

  &--text-small {
    font-size: $text--1;
    font-weight: 500;

    .author__name,
    .author__content {
      font-size: $text--1;
    }
  }
}
