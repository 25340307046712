@import '../rich-text/rich-text';

.callout {
  border-radius: $border-radius--2;
  background-color: $color--beige;

  &__grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 60px;
    align-self: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @add-mixin media s600 {
      grid-template-columns: 1fr 1fr;
    }
  }

  &__heading {
    position: relative;
    display: inline-flex;
    align-items: center;
    min-height: 54px;
    font-size: $text--4;
    line-height: 1.25;
    font-weight: 700;
    padding: $space--2;
    background-color: $color--purple;
    color: #fff;
    border-radius: $border-radius--2 0 0;
    overflow: hidden;

    @add-mixin media s {
      padding-left: $space--6;
      padding-right: $space--9;
    }

    @add-mixin media s {
      max-width: 35rem;
      padding-right: $space--7;
      padding-left: $space--4;
      // line
      &::before {
        content: '';
        position: absolute;
        right: -2px;
        top: -7px;
        width: 0;
        height: 0;
        border-color: transparent transparent $color--teal;
        border-style: solid;
        border-width: 0 0 285px 220px;
        transform: rotate(-10deg);
        right: -24px;
      }

      // triangle
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        right: -34px;
        top: -10px;
        width: 0;
        height: 0;
        border-color: transparent transparent $color--beige;
        border-style: solid;
        border-width: 0 0 285px 220px;
        transform: rotate(-10deg);
      }

      &--yellow::before {
        border-color: transparent transparent $color--yellow;
      }
    }
  }

  &__content-icon {
    @extend .rich-text;
    @extend .rich-text--icon-numbers;
    @extend .rich-text--large-numbers;
    display: flex;
    align-items: center;

    ol li {
      padding-left: 0;
    }
  }
  &__content {
    @extend .rich-text;
    @extend .rich-text--alternative-numbers;
    @extend .rich-text--large-numbers;

    padding: $space--6;
  }

  // spotlight block
  &--case-study {
    color: $color--purple;
    background-color: $color--teal-60;

    .callout__heading {
      margin: 0;
      font-size: $text--5 !important;
      font-weight: 900;

      &::before {
        border-color: transparent transparent #fff;
      }

      &::after {
        border-color: transparent transparent $color--teal-60;
      }
    }

    &-alternative {
      @extend .callout--case-study;
      background-color: $color--orange-light;
      .callout__heading {
        &::after {
          border-color: transparent transparent $color--orange-light;
        }
      }

      .page--campaign-guidance & {
        background-color: $color--orange;

        p {
          font-weight: $font-weight--3;
        }

        li {
          color: $color--text-grey;
          margin: $space--5 0;
          font-weight: $font-weight--3;
        }

        .callout__heading {
          &::after {
            border-color: transparent transparent $color--orange;
          }
        }
      }
    }

    // rich-text override
    .callout__content ul li:before {
      background-color: #fff;
    }
  }

  &--yellow-strip {
    .callout__heading:before {
      border-bottom-color: $color--yellow;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--tools &,
  &--yellow-themed {
    .callout__heading:before {
      border-bottom-color: $color--yellow;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--news &,
  .page--news-insights &,
  [class*='publication'] & {
    &__heading::before {
      border-color: transparent transparent $color--orange;
    }

    &.callout--case-study {
      color: $color--purple;
      background-color: $color--orange-light;

      .callout__heading {
        color: #fff;
        background-color: $color--purple;

        &::before {
          border-color: transparent transparent #fff;
        }

        &::after {
          border-color: transparent transparent $color--orange-light;
        }
      }
    }
  }
}
