.document-block {
  &__link {
    display: inline-flex;
    align-items: center;
    padding: $space--4;
    border: 2px solid $color--purple;
    border-radius: $border-radius--2;
    transition: color $transition--default,
      background-color $transition--default;

    &:hover {
      color: #fff;
      text-decoration: none;
      background-color: $color--purple;
    }
  }

  &__heading {
    color: $color--purple;
    font-size: $text--2;
    font-weight: 900;
  }

  &__size {
    font-size: $text--1;
    color: $color--text-grey;
  }

  &__icon {
    max-height: 60px;
    width: auto;
    margin-left: $space--6;
    color: $color--blue;
  }

  &:hover &__heading,
  &:hover &__size,
  &:hover &__icon {
    color: inherit;
  }
}
