.illustration {
  width: 100%;

  &--woman-with-ipad-circle {
    width: 210px;
    height: 210px;
    background-image: url('./woman-with-ipad-circle.svg');
    background-position: center;
    background-size: cover;
  }

  &--man-waving-circle {
    width: 210px;
    height: 210px;
    background-image: url('./man-waving-circle.svg');
    background-position: center;
    background-size: cover;
  }

  &--woman-with-ponytail-circle {
    width: 210px;
    height: 210px;
    background-image: url('./woman-with-ponytail-circle.svg');
    background-position: center;
    background-size: cover;
  }

  &--woman-in-an-office {
    width: 240px;
    height: 232px;
    background-image: url('./woman-in-an-office.svg');
    background-position: center;
    background-size: cover;

    @add-mixin media sm {
      width: 380px;
      height: 368px;
    }
  }
}
