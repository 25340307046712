.post-meta {
  display: flex;
  align-items: center;
  font-size: $text--1;

  &__icon {
    position: relative;
    height: 24px;
    width: 24px;
    margin-right: $space--3;

    // vertically align with text
    top: -3px;
  }

  &__tag {
    margin-right: $space--3;
    font-weight: bold;
  }
}
