.promo-card {
  display: block;
  cursor: pointer;

  &:focus-within {
    outline: currentColor auto 1px;
    outline: -webkit-focus-ring-color auto 1px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      // focus style is applied to .card root
      outline: none;
    }
  }

  &__inner {
    // not applied to root element since flex margins don't collapse
    display: flex;

    flex-direction: column;
    align-items: center;
    color: $color--blue;
    border: 2px solid $color--purple;
    border-radius: $border-radius--2;
    font-size: $text--5;
    background-color: #fff;
    transition: background-color $transition--default;

    &:hover {
      color: #fff;
      text-decoration: none;
      background-color: $color--purple;
    }

    @add-mixin media s600 {
      flex-direction: row;
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $space--6;
  }

  &__icon__container {
    flex-shrink: 0;
    align-self: flex-end;
    margin-left: 1.25rem;
  }

  &__icon {
    width: 40px;
    height: 40px;
    transition: transform $transition--default;
  }

  &:hover &__icon {
    transform: translateX(3px);
  }

  &__heading {
    color: $color--purple;
    line-height: 1.275;
    margin-bottom: 0.5em;
  }

  &:hover &__heading {
    color: inherit;
  }

  &__image {
    max-width: 200px;
    margin: $space--5 auto 0;

    @add-mixin media s600 {
      margin-top: 0;
    }
  }

  &__text-container {
    min-width: 30vw;
  }

  &--large-image {
    flex-direction: column;

    .promo-card__image {
      margin: $space--5 auto 0;
    }
  }

  &--slant {
    background: none;
    color: $color--purple;
    border-radius: $border-radius--2 0 0 $border-radius--2;

    .promo-card__inner {
      flex-direction: row;
      border: none;

      font-size: $text--4;
    }

    .promo-card__content {
      padding: 0;
      border-radius: $border-radius--2;
      background: #fff;
    }

    .promo-card__icon__container {
      color: $color--blue;
      margin: auto 0;
      padding-right: $space--6;
      display: flex;
      align-items: center;
      flex-shrink: unset;

      @add-mixin media xxs {
        flex-direction: column;
      }
    }

    // element currently only exists on slant variant
    .promo-card__icon-label {
      display: inline-block;
      margin-right: $space--1;
      font-weight: 700;
      max-width: 15ch;
      text-align: right;
      margin-right: $space--2;

      font-size: $text--4;
      line-height: 1.25;
    }

    .promo-card__icon {
      overflow: visible;
      width: 2.5rem;
    }

    .promo-card__heading__container {
      border-radius: $border-radius--2 0 0 $border-radius--2;
      background-color: $color--teal;
      transition: background-color 0.25s ease;
      padding: $space--5 $space--6 $space--5 $space--5;
      position: relative;
      width: 60%;
      color: $color--purple;

      @add-mixin media s {
        width: 77%;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(to right top, #fff 50%, rgba(0, 0, 0, 0) 0);
        width: 4.5rem;
        height: 100%;
        transform: scaleX(-1);
      }

      &--background-salmon {
        background-color: $color--orange;
      }
    }
    @add-mixin media s500 {
      .promo-card__heading__container {
        padding: $space--5 $space--9 $space--5 $space--5;
      }

      .promo-card__heading {
        max-width: 10ch;
        font-size: $text--4;
      }

      .promo-card__inner {
        font-size: $text--2;
      }

      .promo-card__icon__container {
        padding-right: $space--3;
        margin: auto, 0, auto, 0;

        .promo-card__icon-label {
          text-align: right;
          margin-right: $space--2;
          font-size: $text--1;
        }

        .promo-card__icon {
          width: 1.9rem;
        }
      }
    }

    &:hover {
      background: none;
      color: #fff;
    }

    &:hover .promo-card__heading {
      color: #fff;
    }

    &:hover .promo-card__heading__container {
      background-color: $color--purple;
      color: #fff;
    }
  }
}
