.side-menu {
  &__items {
    display: flex;
    white-space: nowrap;
    overflow: auto;

    @add-mixin media m {
      display: block;
      white-space: normal;
    }
  }

  &__item {
    display: block;
    font-family: $font--museo;
    padding: $space--4;
    color: $color--blue;
    font-weight: normal;
    font-size: $text--3;

    &:last-child {
      @extend .mb-0;
    }

    &:hover {
      text-decoration: none;
    }

    &--active {
      @extend .side-menu__item;
      background: $color--grey-light;
    }

    &__item:active,
    &__item:focus {
      @extend .side-menu__item--active;
    }

    @add-mixin media m {
      padding-left: $space--6;
      padding-right: $space--6;
    }
  }
}
