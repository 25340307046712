.layout-full-width {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: $constrain--l;
  padding: $space--5;
  margin: 0 auto;

  @add-mixin media s {
    padding: 0 $gutter;
  }

  &__row {
    justify-content: space-between;
    display: flex;
    flex-direction: column;

    @add-mixin media l {
      flex-direction: row;
    }
  }
}
