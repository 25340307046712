// Todo: avoid duplication with text-video-block component
.text-datawrapper-block {
  color: $color--purple;
  font-size: $text--4;
  line-height: 1.7;
  background-color: $color--sand;

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__text {
    min-width: 50%;
    padding: $space--7;
  }

  &__heading {
    margin-bottom: $space--4;
    max-width: 30ch;
    font-family: $font--verlag;
    font-size: $text--6;
    font-weight: 900;
    text-transform: uppercase;
    line-height: 1.3;
  }

  &__embed {
    max-width: 750px;
    min-width: 50%;
  }

  &__supported {
    padding: 0 $space--7;
  }

  @add-mixin media s {
    &__content {
      flex-direction: row-reverse;
    }

    &--embed-left &__content {
      flex-direction: row;
    }
  }
}
