.nav-primary {
  margin-bottom: $space--7;
  font-size: $text--2;
  font-weight: 700;
  border-top: 1px solid $color--beige;
  border-bottom: 1px solid $color--beige;
  background-color: #fff;

  &__list {
    display: flex;
    flex-wrap: wrap;
    min-height: 50px;
    margin: 0 calc($space--4 * -1);
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__link {
    display: block;
    padding: $space--2 $space--4;
    color: $color--purple;

    &--active {
      border-bottom: 2px solid $color--teal;
    }
  }

  [class*='publication'] &__link {
    &--active {
      border-bottom: 2px solid $color--orange;
    }
  }
}
