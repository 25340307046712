.short-callout {
  position: relative;
  max-width: none;
  padding: $space--4 $space--6;
  font-size: $text--4;
  color: $color--purple;
  font-weight: 700;
  background-color: $color--sand;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: $color--purple;
  }
}
