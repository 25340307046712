.carousel {
  padding: $space--7 0;
  background-color: $color--sand;

  .page--home & {
    &__heading {
      @extend .heading--primary;
      font-size: $text--6;

      @add-mixin media s {
        font-size: $text--8;
      }
    }
  }

  .page--campaign-landing & {
    &__heading {
      @extend .heading--primary;
    }
  }

  &__content {
    @extend .constrain-l;

    position: relative;
  }

  &__track,
  &__slides {
    max-width: 100%;
    overflow: visible;
  }

  &__outer {
    position: relative;
  }

  &__inner {
    margin: 0 auto $space--5;
    padding: $space--2 $space--4;
    width: calc(100% - calc($space--1 * 2));
    overflow: hidden;

    @add-mixin media s {
      width: calc(100% - $space--8);
    }
  }

  &__slides {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    white-space: nowrap;
    will-change: transform;
  }

  &__slide {
    width: 100%;
    flex-shrink: 0;
    white-space: normal;
  }

  &__heading {
    margin-bottom: $space--5;
    font-size: $text--6;
    font-weight: 700;
    color: $color--purple;
  }

  &__prev,
  &__next {
    position: absolute;
    top: calc(50% - 1rem);
    transform: translateY(-50%);
    margin: auto;
    padding: 0;
    display: none;
    background: transparent;

    &:hover {
      color: $color--purple;
    }

    @add-mixin media s {
      color: $color--blue;
      transition: color $transition--default;
      display: inline;
    }
  }

  &__prev {
    left: 0;
  }

  &__next {
    right: 0;
  }

  &__liveregion {
    @extend .visually-hidden;
  }
}

.glide {
  &__bullets {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    margin-top: $space--5;
  }

  @media (max-width: 800px) {
    &__bullets {
      display: none;
      visibility: hidden;
    }
  }

  &__bullet {
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: rgba(68, 68, 68, 0.4);

    // remove default padding from button element on iOS
    padding: 0;

    &:not(:nth-child(3n + 1)) {
      display: none;
    }

    &--active {
      background-color: $color--purple;
    }

    & + & {
      margin-left: $space--3;
    }
  }
}
