$size: 3rem;

.connected-steps {
  // position: relative;
  font-size: $text--2;
  counter-reset: item;

  &__item {
    position: relative;
    margin-left: calc(($size / 2) * -1);

    &:not(:first-child):before {
      content: '';
      position: absolute;
      top: 0;
      left: calc($size / 2);
      width: 4px;
      height: calc(50% - ($size / 2));
      background-color: $color--teal;
    }

    &:not(:last-child):after {
      content: '';
      position: absolute;
      bottom: 0;
      left: calc($size / 2);
      width: 4px;
      height: calc(50% - ($size / 2) + $space--5);
      background-color: $color--teal;
    }
  }

  &__item:first-child &__link {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &__item:last-child &__link {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__item--alternative {
    &:not(:first-child):before {
      background-color: $color--orange;
    }
    &:not(:last-child):after {
      background-color: $color--orange;
    }
  }

  &__link {
    display: flex;
    justify-content: space-between;
    margin-left: calc($size / 2);
    color: inherit;
    counter-increment: item;
    background-color: $color--beige;

    &:hover {
      text-decoration: none;
    }

    &:hover .connected-steps__content {
      color: #fff;
      background-color: $color--purple;
    }

    &:hover .connected-steps__heading {
      color: #fff;
    }

    &:hover:before {
      background-color: $color--purple;
    }

    &:before {
      content: counter(item);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: $size;
      height: $size;
      left: 3px;
      top: -$space--2;
      bottom: 0;
      margin: auto;
      border-radius: 50%;
      border: 2px solid $color--beige;
      color: #fff;
      font-weight: 900;
      z-index: 1;
      background-color: $color--teal;
    }
  }

  &__link--no-numbers {
    &:before {
      content: '';
    }
  }

  &__link--alternative {
    &:before {
      background-color: $color--orange;
    }
  }

  &__content {
    display: flex;
    align-items: flex-end;
    flex-grow: 1;
    padding-top: $space--5;
    padding-bottom: $space--5;
    padding-left: $space--7;
    padding-right: $space--5;
    background-color: $color--white;
  }

  &__item:not(:last-child) &__content {
    margin-bottom: $space--2;
  }

  &__text {
    flex-grow: 1;
  }

  &__heading {
    font-size: $text--6;
    font-weight: 900;
    color: $color--purple;
  }

  &__description {
    margin-top: $space--3;
  }

  &__icon {
    flex-shrink: 0;
    margin-left: $space--4;
    color: $color--teal;
  }

  &__icon--alternative {
    color: $color--orange;
  }
}
