.post-meta-bottom {
  @extend .constrain-l;

  display: flex;
  justify-content: flex-end;
  margin-top: $space--9;
  margin-bottom: $space--4;
  font-size: $text--1;

  &:before {
    content: '';
    width: 1.75em;
    height: 4px;
    position: relative;
    right: -2px;
    bottom: 0;
    border-radius: $border-radius--2;
    background-color: $color--purple;
    transform: rotate(-58deg);
  }
}
