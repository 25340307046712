$button-toggle-width: 42px;

.org-member-strip {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  border-top: 1px solid #d4d5cf;
  padding: $space--2 0;
  background-color: #fff;

  &__icon {
    width: 18px;
    height: 26px;
    display: block;
  }

  &__profile {
    display: flex;
    flex-direction: row;
    margin-top: $space--2;
    margin-bottom: $space--2;
  }

  &__details {
    margin-left: $space--4;
    margin-right: $space--4;
    font-size: $text--2;
    word-wrap: break-word;
  }

  &__manage {
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: $space--2;
    margin-bottom: $space--2;
    white-space: nowrap;
  }

  &__toggle-more {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: $button-toggle-width;
    border: 1px solid $color--blue;
    border-radius: 50%;
    color: $color--blue;
    background: none;
    transition: color $transition--default,
      background-color $transition--default;

    &:hover,
    &[aria-expanded='true'] {
      color: #fff;
      background-color: $color--blue;
    }

    > * {
      display: block;
      height: 4px;
      width: 4px;
      border-radius: 50%;
      background-color: currentColor;
    }

    > * + * {
      margin-left: $space--1;
    }
  }

  &__actions {
    position: absolute;
    top: -30px !important;
    right: calc($button-toggle-width + $space--2);
    padding: $space--3;
    border: 1px solid $color--blue;
    border-radius: 3px;
    background-color: #fff;
    z-index: 10;

    > * + * {
      margin-top: $space--3;
    }

    .button {
      justify-content: center;
      width: 100%;
    }
  }

  @add-mixin media s {
    padding-left: $space--4;
    padding-right: $space--4;
  }

  @add-mixin media m {
    padding-left: $space--6;
    padding-right: $space--6;
  }
}
