// BLOCK tabbed
.tabbed {
  &--tabs-above {
    position: relative;
    z-index: 1;
  }

  // ELEMENT tablist
  &__tablist {
    display: flex;
    white-space: nowrap;
    overflow-x: auto;
    font-weight: $font-weight--5;
    justify-content: space-between;
    &--card {
      justify-content: flex-start;
      font-weight: $font-weight--2;
      font-size: $text--1;
    }
    .tabbed__tablist__anchor {
      display: block;
      text-decoration: none;
      padding: $space--2 $space--4;
      color: $color--purple;
      border-top: $space--1 solid transparent; // to keep the spacing consistent
      border-radius: $space--1 $space--1 0 0; // $space--1 to match the border size so the bottom of the border remains flat

      &[aria-selected] {
        background: $color--sand;
        position: relative;
        border: 0 none $color--purple;
        border-top: $space--1 solid $color--purple;
      }
      &--card {
        color: $color--text-grey;
        border-top: $space--1 solid $color--grey;
        background: $color--sand;

        &[aria-selected] {
          background: $color--white;

          border: 0 none $color--purple;
          border-top: $space--1 solid $color--purple;
        }
      }
    }
  }

  &__tabpanel {
    background-color: $color--sand;

    &--card {
      background-color: transparent;
    }
  }
}
