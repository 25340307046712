.steps-nav {
  display: flex;
  flex-wrap: wrap;
  margin: calc($space--6 - $space--2) calc($space--2 * -1);
  margin-top: $space--4;
  overflow: hidden;

  &__step {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    min-width: 250px;
    margin: $space--2;
    padding: $space--3;
    border: 2px solid $color--purple;
    border-radius: $border-radius--2;
    color: $color--purple;
    font-size: $text--4;
    font-weight: 700;
    line-height: 1.25;
    transition: background-color $transition--default;

    &:hover {
      color: #fff;
      text-decoration: none;
      background-color: $color--purple;
    }

    &:hover .steps-nav__icon {
      color: #fff;
    }
  }

  &__icon {
    flex-shrink: 0;
    color: $color--blue;
    transform: scaleX(-1);
  }

  &__step--prev &__icon {
    order: -1;
    margin-right: $space--5;
  }

  &__step--next &__icon {
    margin-left: $space--5;
    transform: none;
  }

  &__step:nth-child(2) &__icon {
    margin-left: $space--5;
    margin-right: 0;
  }

  @add-mixin media s {
    margin: calc($space--5 * -1) calc(($space--6 * -1) - $space--6);
    margin-top: $space--6;

    &__step {
      flex: 0 0 calc(50% - $space--7);
      margin: $space--5;
      padding: $space--5;
      font-size: $text--5;
    }

    &__step--next {
      margin-left: auto;
    }
  }
}
