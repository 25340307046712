.warning-modal {
  // position: absolute;
  top: 0;
  right: 0;
  width: max-content;
  max-width: 300px;
  padding: $space--3;
  border: 1px solid $color--blue;
  border-radius: 3px;
  font-size: $text--1;
  color: $color--text-grey;
  background-color: #fff;
  white-space: normal;
  z-index: 10;
  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: $space--3;
    > * + * {
      margin-left: $space--3;
    }
  }

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
