.share {
  font-size: $text--1;

  &__heading {
    @extend .heading;
    @extend .heading--small;
  }

  &__links {
    display: flex;
    align-items: center;
    margin-top: $space--3;
    list-style: none;

    .share__link {
      &:before {
        display: none;
      }
      padding: 0;
      margin: 0;

      & + .share__link {
        margin-left: $space--2;
      }

      display: block;
    }
  }

  &__footer {
    margin-top: $space--3;
  }
}
