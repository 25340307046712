.quote {
  quotes: '\201C''\201D''\2018''\2019';
  padding: $space--6;
  background-color: $color--sand;

  &__body {
    display: inline;
    font-size: $text--6;
    font-weight: 700;
    color: $color--purple;

    &:before {
      content: open-quote;
    }

    &:after {
      content: close-quote;
    }
  }

  &__author {
    margin-top: $space--5;
    font-size: $text--1;
    font-style: normal;

    position: relative;
    display: block;
    padding-left: $space--5;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 2em;
      height: 4px;
      border-radius: $border-radius--2;
      background-color: $color--purple;
      transform: rotate(-58deg);
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--tools &,
  &--yellow {
    .quote__author:before {
      background-color: $color--yellow;
    }
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--news &,
  .page--news-index &,
  .page--news-insights &,
  .page--campaign-guidance &,
  .events &,
  [class*='publication'] & {
    .quote__author:before {
      background-color: $color--orange;
    }
  }

  .events &,
  .training & {
    background-color: $color--white;
  }

  // Todo: avoid page-specific variants by moving to themable components
  .page--information &,
  .page--hg-index &,
  .training &,
  .page--hg-guidance & {
    .quote__author:before {
      background-color: $color--teal;
    }
  }
}
