.index-card {
  display: flex;
  height: 100%;
  font-size: $text--3;
  color: $color--purple;
  background-color: #fff;
  transition: background-color $transition--default;

  &:not(.index-card--split):hover {
    color: #fff;
    text-decoration: none;
    background-color: $color--purple;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: $space--5;
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  &__icon {
    flex-shrink: 0;
    align-self: flex-end;
    width: 24px;
    height: 24px;
    margin-left: $space--2;
    color: $color--teal;
    transition: transform $transition--default;

    &--orange {
      color: $color--orange;
    }
  }

  &:hover &__icon {
    transform: translateX(3px);
  }

  &__heading {
    color: $color--purple;
    font-size: $text--4;
    line-height: 1.275;
    margin-bottom: $space--2;
  }

  &:hover &__heading {
    color: inherit;
  }

  &--split &__link:hover {
    color: $color--blue;
  }

  &__link {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }

  &__subheading {
    font-size: $text--1;
    font-weight: normal;
  }

  &__image-wrapper {
    @extend .round-image;

    align-self: flex-start;
    margin-right: $space--5;

    &:after {
      transition: background-color $transition--default;
    }
  }

  &__image {
    @extend .round-image__image;
  }

  &:hover &__image-wrapper:after {
    background-color: $color--purple;
  }

  &__list-link {
    display: block;
    padding: $space--2 0;
    border-top: 1px solid rgb(0, 0, 0, 0.15);
    font-size: $text--2;
    color: $color--blue;
  }

  &--person {
    color: $color--text-grey;

    .index-card__heading {
      margin-bottom: $space--1;
    }
  }
}
